<template>
  <div id="page-login">
    <div id="login-container" class="bg-primary shadow">
      <div class="login-title">
        <img src="@/assets/petronas-logo.png" />
      </div>
      <div class="row mx-0 text-center bg-white">
        <div class="col px-0">
          <button
            class="btn btn-block rounded-0 py-3"
            :class="{
              'bg-primary text-white font-weight-medium':
                formView == 'register',
            }"
            @click="changeForm('login')"
          >
            LOGIN
          </button>
        </div>
        <div class="col px-0">
          <button
            class="btn btn-block rounded-0 py-3"
            :class="{
              'bg-primary text-white font-weight-medium': formView == 'login',
            }"
            @click="changeForm('register')"
          >
            REGISTER
          </button>
        </div>
      </div>
      <div class="login-form pt-3" v-if="formView == 'login'">
        <p
          class="text-center text-danger font-weight-medium"
          v-html="errorMessage"
        ></p>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control rounded"
            id="email"
            v-model="models.identifier"
            @keyup.enter="enter('email')"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control rounded"
            id="password"
            v-model="models.password"
            @keyup.enter="enter('password')"
          />
        </div>
        <div class="py-3">
          <button
            class="btn btn-block btn-primary"
            :disabled="disableButton"
            @click="login()"
          >
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Logging in...</span>
              </div>
            </span>
            <span v-else>Login</span>
          </button>
        </div>
      </div>
      <div class="login-form" v-if="formView == 'register'">
        <p class="text-center text-danger font-weight-medium">
          {{ errorMessage }}
        </p>
        <div class="form-group">
          <label for="fullname">Full Name</label>
          <input
            type="text"
            class="form-control rounded"
            id="fullname"
            v-model="registerModels.fullname"
            @keyup.enter="enter('fullname')"
          />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control rounded"
            id="email"
            v-model="models.identifier"
            @keyup.enter="enter('email')"
          />
        </div>
        <div class="form-group">
          <label for="password" @click="showPasswordRequirement()">
            Password
            <i class="fas fa-exclamation-circle ml-2"></i>
          </label>
          <input
            type="password"
            class="form-control rounded"
            id="password"
            v-model="models.password"
            @keyup.enter="enter('password')"
          />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input
            type="password"
            class="form-control rounded"
            id="confirmPassword"
            v-model="registerModels.confirmPassword"
            @keyup.enter="enter('confirmPassword')"
          />
        </div>
        <div class="py-3">
          <button
            class="btn btn-block btn-primary"
            :disabled="disableButton"
            @click="register()"
          >
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Creating your account...</span>
              </div>
            </span>
            <span v-else>Create account</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "page-login",
  data() {
    return {
      models: {
        identifier: null,
        password: null,
      },
      registerModels: {
        fullname: null,
        confirmPassword: null,
      },
      errorMessage: null,
      isLoading: false,
      formView: "login",
      passwordValidator: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/,
    };
  },
  computed: {
    disableButton() {
      if (this.isLoading) {
        return false;
      } else {
        if (this.formView == "login") {
          return !this.models.identifier || !this.models.password;
        } else if (this.formView == "register") {
          return (
            !this.models.identifier ||
            !this.models.password ||
            !this.registerModels.fullname ||
            !this.registerModels.confirmPassword
          );
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    changeForm(type) {
      this.errorMessage = null;

      this.formView = type;
    },
    enter(type) {
      if (this.formView == "login") {
        if (type == "email") {
          $("#password").focus();
        } else {
          this.login();
        }
      } else if (this.formView == "register") {
        if (type == "fullname") {
          $("#email").focus();
        } else if (type == "email") {
          $("#password").focus();
        } else if (type == "password") {
          $("#confirmPassword").focus();
        } else {
          this.register();
        }
      }
    },
    register() {
      if (this.disableButton) {
        this.errorMessage = "Invalid email / password";
      } else if (!this.models.password.match(this.passwordValidator)) {
        this.errorMessage = "Password does not meet criteria";
      } else if (this.models.password != this.registerModels.confirmPassword) {
        this.errorMessage = "Password does not match";
      } else {
        this.isLoading = true;
        this.errorMessage = null;

        let body = {
          username: this.registerModels.fullname,
          email: this.models.identifier,
          password: this.models.password,
          confirmed: true,
        };

        API.post("auth/local/register", body)
          .then((retVal) => {
            this.isLoading = false;

            Swal.fire({
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonText: "OK",
              buttonsStyling: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              icon: "success",
              html:
                "Your registration is successful. Once you get admin approval, you can login using your email and password",
            }).then((result) => {
              if (result.isConfirmed) {
                this.formView = "login";

                this.models.identifier = retVal.data.user.email;
                this.registerModels.fullname = null;
                this.registerModels.confirmPassword = null;
              }
            });
          })
          .catch((err) => {
            console.error(err.response);

            if (
              err.response.data.message[0].messages[0].id ==
              "Auth.form.error.email.taken"
            ) {
              this.errorMessage = "Email is already registered";
            } else {
              this.errorMessage = "Invalid email / password";
            }

            this.isLoading = false;
          });
      }
    },
    login() {
      if (!this.disableButton) {
        this.isLoading = true;
        this.errorMessage = null;

        API.post("auth/local", this.models)
          .then(async (retVal) => {
            if (!retVal.data.user.approved) {
              this.errorMessage =
                "Your account is not yet approved.<br />Please get approval from admin to get full access";
            } else {
              localStorage.setItem("token", retVal.data.jwt);

              let userObject = {
                id: retVal.data.user._id,
                email: retVal.data.user.email,
                fullname: retVal.data.user.username,
                role: retVal.data.user.type,
              };

              localStorage.setItem("user", JSON.stringify(userObject));

              this.$router.replace({
                name: "CCTV",
              });
            }

            this.isLoading = false;
          })
          .catch((err) => {
            console.error(err);

            this.errorMessage = "Invalid email / password";

            this.isLoading = false;
          });
      }
    },
    showPasswordRequirement() {
      Swal.fire({
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Got it!",
        buttonsStyling: false,
        customClass: {
          cancelButton: "btn btn-primary",
          content: "text-left px-0 pt-4",
        },
        icon: "",
        html:
          "<h5 class='mb-4 text-center text-dark'>Your password must meet all criterias below:</h5>" +
          "<ul>" +
          "<li>At least 8 characters</li>" +
          "<li>A mixture of both uppercase and lowercase letters</li>" +
          "<li>A mixture of letters and numbers</li>" +
          "</ul>",
      });
    },
  },
};
</script>